@import 'src/styles/media';
@import 'src/styles/typography';

.PageStartupBlock__button {
  margin: 84px auto 0;
  border-radius: 20px;
  width: 100%;
  height: 68px;
  max-width: 440px;
  font-family: $font-family-secondary;
  font-size: 20px;
  line-height: 28px;

  @include media-up(breakpoint-md) {
    height: 82px;
    font-size: 26px;
    line-height: 36px;
  }
}

.PageStartupBlock__icon {
  width: 24px;
  height: 24px;
}