@import 'src/styles/media';

.FAQBlockImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FAQBlockImage__title {
  order: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  @include media-up(breakpoint-md) {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: left;
  }

  @include media-up(breakpoint-xl) {
    order: unset;
    margin-top: 0;
  }
}

.FAQBlockImage__image {
  display: none;

  @include media-up(breakpoint-md) {
    position: relative;
    display: block;
    width: 412px;
    height: 412px;
  }
}