@import 'src/styles/media';

.PageGetMatchedBlock {
  overflow-x: hidden;
  padding: 82px 0;
  background: linear-gradient(180.05deg, #fff 0.04%, rgb(223 223 253 / 74%) 17.22%, rgb(223 223 253 / 41%) 73.32%, rgb(255 255 255 / 10%) 99.96%);

  @include media-up(breakpoint-md) {
    padding: 126px 0;
  }
}

.PageGetMatchedBlock__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageGetMatchedBlock__block {
  &:last-of-type {
    display: none;
  }

  @include media-up(breakpoint-md) {
    &:first-of-type {
      flex-basis: 80%;
      margin-right: 24px;
    }

    &:last-of-type {
      display: block;
      margin-right: -28%;
      width: 464px;
    }
  }

  @include media-up(breakpoint-lg) {
    &:first-of-type {
      flex-basis: calc(50% - 60px);
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
      width: auto;
    }
  }
}
