@import 'src/styles/media';

.AwardBlockTitle {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 58px;
}

.AwardBlockTitle__title {
  z-index: 2;
  padding: 0 42px;
  text-align: center;
  background-color: #fff;
}

.AwardBlockTitle__icon {
  display: none;

  @include media-up(breakpoint-md) {
    position: absolute;
    top: -80px;
    z-index: 1;
    display: block;
  }
}

.AwardBlockTitle__icon_left {
  left: 0;
}

.AwardBlockTitle__icon_right {
  right: 0;
}