@import 'src/styles/media';

.PageFAQBlock__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-up(breakpoint-xl) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.PageFAQBlock__block {
  @include media-up(breakpoint-xl) {
    &:first-of-type {
      margin-bottom: 0;
      min-width: 472px;
      max-width: none;
    }

    &:last-of-type {
      flex-grow: 1;
      margin-left: 66px;
    }
  }
}