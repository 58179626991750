@import 'src/styles/tokens/colors';
@import 'src/styles/typography';
@import 'src/styles/media';

.AccordionItemCircle {
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  color: #fff;
  background-color: $color-light-blue;

  @include media-up(breakpoint-md) {
    margin-right: 22px;
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
}

.AccordionItemCircle_animated {
  background-color: $color-original-blue;
}

.AccordionItemCircle__animation {
  content: '';
  position: absolute;
  left: -50%;
  bottom: 0%;
  z-index: 1;
  border-radius: 35%;
  width: 200%;
  height: 200%;
  background-color: $color-light-blue;
}

.AccordionItemCircle__order {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  font-family: $font-family-secondary;
  font-size: 30px;
  line-height: 36px;
  transform: translate(-50%, -50%);

  @include media-up(breakpoint-md) {
    font-size: 40px;
    line-height: 49px;
  }
}
