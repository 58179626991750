@import 'src/styles/tokens/colors';

.AccordionItemDescription__list {
  list-style: inside;
  padding-left: 10px;
}

.AccordionItemDescription__listItem {
  & + & {
    margin-top: 2px;
  }
}

.AccordionItemDescription__description {
  color: $color-dark-grey;
}