@import 'src/styles/media';

.AccordionMobile {
  border-radius: 20px;
  padding: 20px 18px;
  background: #fff;

  @include media-up(breakpoint-md) {
    padding: 32px 38px;
  }
}

.AccordionMobile__title {
  margin-bottom: 10px;

  @include media-up(breakpoint-md) {
    margin-bottom: 20px;
  }
}

.AccordionMobile__images {
  height: 202px;

  @include media-up(breakpoint-md) {
    height: 306px;
  }
}