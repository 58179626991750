@import 'src/styles/media';

.ImageBlock4 {
  position: relative;
  flex-grow: 1;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
  }
}

.ImageBlock4__topImageWrapper {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  transform: translateX(-50%);

  @include media-up(breakpoint-md) {
    top: 12px;
  }

  @include media-up(breakpoint-lg) {
    left: 16px;
    right: 16px;
    top: 30px;
    bottom: 36px;
    transform: none;
  }
}

.ImageBlock4__topImage {
  position: relative;
  width: 344px;
  height: 340px;

  @include media-up(breakpoint-md) {
    width: 556px;
    height: 550px;
  }
}