@import 'src/styles/media';

.AwardBlockInfo {
  display: grid;
  row-gap: 16px;

  @include media-up(breakpoint-md) {
    column-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
  }

  @include media-up(breakpoint-xl) {
    column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 0;
  }
}
