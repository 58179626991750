@import 'src/styles/media';

.VettingAccordion__desktop {
  display: none;

  @include media-up(breakpoint-lg) {
    display: flex;
  }
}

.VettingAccordion__mobile {
  display: block;

  @include media-up(breakpoint-lg) {
    display: none;
  }
}