@import 'src/styles/tokens/colors';
@import 'src/styles/typography';
@import 'src/styles/mixins';

.AccordionItem {
  display: flex;
  padding: 18px 0;
  cursor: pointer;
}

.AccordionItem_notActive {
  align-items: center;
}

.AccordionItem__title {
  margin-bottom: 6px;
}
