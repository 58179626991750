@import 'src/styles/media';

.PageVettingBlock__backgroundImage {
  background-size: 100% 66%;
  background-repeat: no-repeat;

  @include media-up(breakpoint-xxl) {
    background-size: 100% 82%;
  }
}

.PageVettingBlock__backgroundGradient {
  background: linear-gradient(180.05deg, #fff 0.04%, rgb(223 223 253 / 74%) 17.22%, rgb(224 233 253 / 10%) 90.59%, rgb(255 255 255 / 10%) 99.96%);
  background-size: cover;
  background-repeat: no-repeat;
}

.PageVettingBlock__vettingSection {
  @include media-up(breakpoint-md) {
    margin-top: 82px;
  }
}