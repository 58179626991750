@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';

.FounderCard {
  overflow: hidden;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
}

.FounderCard__image {
  margin-bottom: 14px;
}

.FounderCard__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.FounderCard__title {
  color: $color-original-blue;
}

.FounderCard__socialLinks {
  display: flex;
  align-items: center;
}

.FounderCard__socialLink {
  @include svg-icon;

  display: block;
  width: 38px;
  height: 38px;

  & + & {
    margin-left: 10px;
  }
}

.FounderCard__position {
  margin-bottom: 14px;
  color: $color-dark-grey;
}

.FounderCard__description {
  margin-bottom: 18px;
}
