@import 'src/styles/media';

.ImageBlock2 {
  position: relative;
  flex-grow: 1;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
  }
}

.ImageBlock2__imageWrapper {
  position: absolute;
  z-index: 1;
}

.ImageBlock2__topImageWrapper {
  left: 10px;
  top: 10px;

  @include media-up(breakpoint-md) {
    left: 18px;
    top: 18px;
  }

  @extend .ImageBlock2__imageWrapper;
}

.ImageBlock2__bottomImageWrapper {
  right: -24px;
  bottom: -14px;

  @include media-up(breakpoint-md) {
    right: 10px;
    top: 86px;
  }

  @include media-up(breakpoint-lg) {
    right: 18px;
    top: auto;
    bottom: 18px;
  }

  @extend .ImageBlock2__imageWrapper;
}

.ImageBlock2__topImage {
  position: relative;
  width: 200px;
  height: 162px;

  @include media-up(breakpoint-md) {
    width: 440px;
    height: 356px;
  }
}

.ImageBlock2__bottomImage {
  position: relative;
  width: 200px;
  height: 154px;

  @include media-up(breakpoint-md) {
    width: 474px;
    height: 356px;
  }
}