@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';

.AccordionList__list {
  @include list-reset;
}

.AccordionList__listItem {
  border-top: 1px solid transparent;

  & + & {
    border-top-color: $color-grey;
  }
}