@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.GetMatchedForm__title {
  margin-bottom: 26px;
  color: $color-dark-blue;
}

.GetMatchedForm__formInner {
  @include media-up(breakpoint-sm) {
    display: flex;
    align-items: center;
  }
}

.GetMatchedForm__input {
  width: 100%;

  @include media-up(breakpoint-sm) {
    margin-right: 24px;
    max-width: 442px;
  }
}

.GetMatchedForm__btn {
  margin-top: 20px;
  width: 100%;
  padding: 12px 44px;
  font-weight: 400;
  font-size: 18px;
  height: auto;

  @include media-up(breakpoint-sm) {
    margin-top: 0;
    width: auto;
  }
}