@import 'src/styles/mixins';

.FAQBlockQuestions__list {
  @include list-reset;

  display: flex;
  flex-direction: column;
}

.FAQBlockQuestions__question {
  & + & {
    margin-top: 16px;
  }
}