@import 'src/styles/media';

.ImageBlock3 {
  position: relative;
  flex-grow: 1;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
  }
}

.ImageBlock3__imageWrapper {
  position: absolute;
  z-index: 1;
}

.ImageBlock3__topImageWrapper {
  left: 10px;
  top: 10px;

  @include media-up(breakpoint-md) {
    left: -64px;
    top: 22px;
  }

  @include media-up(breakpoint-lg) {
    left: 32px;
    top: 32px;
  }

  @extend .ImageBlock3__imageWrapper;
}

.ImageBlock3__bottomImageWrapper {
  right: -12px;
  bottom: 6px;

  @include media-up(breakpoint-md) {
    right: -62px;
    bottom: -54px;
  }

  @include media-up(breakpoint-lg) {
    right: 26px;
    bottom: 44px;
  }

  @extend .ImageBlock3__imageWrapper;
}

.ImageBlock3__topImage {
  position: relative;
  width: 210px;
  height: 118px;

  @include media-up(breakpoint-md) {
    width: 468px;
    height: 264px;
  }
}

.ImageBlock3__bottomImage {
  position: relative;
  width: 194px;
  height: 110px;

  @include media-up(breakpoint-md) {
    width: 468px;
    height: 264px;
  }
}