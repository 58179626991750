@import 'src/styles/tokens/colors';

.Footer1 {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.Footer1__text {
  color: $color-dark-grey;

  & + & {
    margin-top: 4px;
  }
}