@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/media';

.AccordionImages {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  border-radius: 20px;
  background: linear-gradient(46.61deg, #fff 0%, #373af5 120.01%);
}

.AccordionImages__btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: $color-original-blue;
  background-color: #fff;
  transition: color $transition-main, background-color $transition-main;

  span {
    display: none;
  }

  &:hover {
    color: #fff;
    background-color: $color-dark-blue;
  }

  @include media-up(breakpoint-md) {
    right: 16px;
    top: 16px;
    border-radius: 100px;
    padding: 8px 16px;
    width: auto;
    height: auto;

    span {
      display: block;
    }
  }
}

.AccordionImages__btnIcon {
  @include media-up(breakpoint-md) {
    margin-left: 10px;
  }
}

.AccordionImages__imagesWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-grow: 1;
}