@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/media';

.MediaBlockTitle {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
  }
}

.MediaBlockTitle__title {

}

.MediaBlockTitle__arrows {
  display: flex;
  align-items: center;
  margin-top: 16px;

  @include media-up(breakpoint-md) {
    margin-top: 0;
  }
}

.MediaBlockTitle__arrow {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  color: $color-original-blue;
  background-color: $color-bg;
  transition: color $transition-main, background-color $transition-main;

  svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    color: #fff;
    background-color: $color-original-blue;
  }

  & + & {
    margin-left: 10px;
  }

  @include media-up(breakpoint-md) {
    width: 70px;
    height: 70px;
    min-width: 70px;

    svg {
      width: 38px;
      height: 38px;
    }
  }
}

.MediaBlockTitle__arrow_left {
  svg {
    transform: rotate(180deg);
  }

  @extend .MediaBlockTitle__arrow;
}

.MediaBlockTitle__arrow_right {
  @extend .MediaBlockTitle__arrow;
}