@import 'src/styles/media';

.PageFoundersBlock {

}

.PageFoundersBlock__title {
  margin-bottom: 60px;
}

.PageFoundersBlock__cards {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  @include media-up(breakpoint-md) {
    column-gap: 32px;
    grid-template-columns: 1fr 1fr;
    row-gap: 32px;
  }

  @include media-up(breakpoint-lg) {
    grid-template-columns: repeat(auto-fit, minmax(390px, 392px));
  }
}