@import 'src/styles/media';

.ImageBlock1 {
  position: relative;
  flex-grow: 1;
  background: transparent;

  img {
    width: 100%;
    height: 100%;
  }
}

.ImageBlock1__imageWrapper {
  position: absolute;
  z-index: 1;
}

.ImageBlock1__topImageWrapper {
  left: 8px;
  top: 10px;

  @include media-up(breakpoint-md) {
    left: 28px;
    top: 20px;
  }

  @include media-up(breakpoint-lg) {
    left: 28px;
    top: 20px;
  }

  @extend .ImageBlock1__imageWrapper;
}

.ImageBlock1__bottomImageWrapper {
  right: -10px;
  bottom: -82px;

  @include media-up(breakpoint-md) {
    right: 18px;
    top: 78px;
  }

  @include media-up(breakpoint-lg) {
    right: 16px;
    top: auto;
    bottom: 0;
  }

  @extend .ImageBlock1__imageWrapper;
}

.ImageBlock1__topImage {
  position: relative;
  width: 226px;
  height: 172px;

  @include media-up(breakpoint-md) {
    width: 432px;
    height: 328px;
  }
}

.ImageBlock1__bottomImage {
  position: relative;
  width: 196px;
  height: 196px;

  @include media-up(breakpoint-md) {
    width: 410px;
    height: 356px;
  }
}