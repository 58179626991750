@import 'src/styles/tokens/colors';
@import 'src/styles/media';
@import 'src/styles/mixins';

.AccordionDesktop {
  display: flex;
  justify-content: space-between;
}

.AccordionDesktop__title {
  margin-bottom: 38px;
}

.AccordionDesktop__block {
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 12px);

  &:first-of-type {
    border-radius: 20px;
    padding: 32px 38px;
    background-color: #fff;
  }

  &:last-of-type {
    min-height: 616px;
  }

  @include media-up(breakpoint-xl) {
    max-width: calc(50% - 20px);
  }
}