@import 'src/styles/tokens/colors';

.MediaBlockSlide {
  border-radius: 20px;
  padding: 32px 34px;
  min-height: 250px;
  background: $color-bg;
}

.MediaBlockSlide__logo {
  margin-bottom: 22px;
}

.MediaBlockSlide__wrapper {
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}