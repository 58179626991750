@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';
@import 'src/styles/media';

.StartupBlockInfo {
  display: flex;
  border-radius: 20px;
  padding: 22px 20px;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    padding: 28px;
  }

  @include media-up(breakpoint-lg) {
    padding: 56px;
  }
}

.StartupBlockInfo__title {
  margin-bottom: 24px;

  @include media-up(breakpoint-md) {
    margin-bottom: 48px;
  }
}

.StartupBlockInfo__content {
  display: flex;
}

.StartupBlockInfo__list {
  @include list-reset;
}

.StartupBlockInfo__listItemWrapper {
  & + & {
    margin-top: 20px;
  }

  @include media-up(breakpoint-md) {
    & + & {
      margin-top: 22px;
    }
  }
}

.StartupBlockInfo__listItem {
  @include media-up(breakpoint-md) {
    display: flex;
  }
}

.StartupBlockInfo__listItemIcon {
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 8px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  color: #fff;
  background-color: $color-original-blue;

  @include media-up(breakpoint-md) {
    margin-right: 12px;
    margin-bottom: 0;
  }

  @include media-up(breakpoint-lg) {
    margin-right: 24px;
    padding: 12px;
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
}

.StartupBlockInfo__listItemTitle {
  margin-bottom: 10px;
}

.StartupBlockInfo__listItemDescription {
  color: $color-dark-grey;
}

.StartupBlockInfo__image {
  display: none !important;

  @include media-up(breakpoint-md) {
    position: relative;
    margin-left: 40px;
    height: 470px;
    min-width: 340px;
  }

  @include media-up(breakpoint-lg) {
    margin-left: 82px;
  }
}

.StartupBlockInfo__desktop {
  display: none !important;

  @include media-up(breakpoint-lg) {
    display: block !important;
  }
}

.StartupBlockInfo__mobile {
  @include media-up(breakpoint-md) {
    display: block !important;
  }

  @include media-up(breakpoint-lg) {
    display: none !important;
  }
}