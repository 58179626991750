@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';

.FAQQuestionAccordion {
  border-radius: 20px;
  padding: 16px 28px;
  background: $color-bg;
  cursor: pointer;

  &:hover {
    .FAQQuestionAccordion__title {
      color: $color-original-blue;
    }

    .FAQQuestionAccordion__icon {
      color: #fff;
      background-color: $color-original-blue;
    }
  }
}

.FAQQuestionAccordion__titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FAQQuestionAccordion__title {
  transition: color $transition-main;
}

.FAQQuestionAccordion__icon {
  margin-left: 16px;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  min-width: 46px;
  transition: background-color $transition-main, color $transition-main;

  svg {
    width: 32px;
    height: 32px;
  }
}

.FAQQuestionAccordion__description {
  margin-top: 12px;
  color: $color-dark-grey;

  & + & {
    margin-top: 24px;
  }
}