@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/media';

.AwardBlockCard {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 24px;
  height: 148px;
  background-color: $color-bg;
  transition: background-color $transition-main, transform $transition-main;

  &:hover {
    background-color: $color-original-blue;
    transform: scale(1.01);

    .AwardBlockCard__bottomLogo,
    .AwardBlockCard__topLogo {
      color: #fff;
    }
  }

  @include media-up(breakpoint-md) {
    height: 160px;
  }
}

.AwardBlockCard__topLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-original-blue;
  transition: color $transition-main;
}

.AwardBlockCard__icon {
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  color: $color-original-blue;
  background-color: #fff;
}

.AwardBlockCard__bottomLogo {
  margin-top: auto;
  color: #9595d6;
  transition: color $transition-main;
}